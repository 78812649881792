<template>
  <div id="allDialogs" class="Content-Page">
    <div id="namePage" class="wrap-Main">
        <div class="box-S4 flex-between-center">
            <div class="N-Page T-size-36">All Dialogs</div>
        </div>
    </div>
    <!-- <div class="wrap-Main flex-between-start">

        <div class="box-S1">
            <div class="box-S4">
                <div class="N-Page T-size-18">Save</div>
            </div>
            <div class="box-S4"> 
                <v-dialog v-model="dialog" persistent max-width="300">
                    <template v-slot:activator="{ on }">
                        <v-btn class="theme-btn-even btn-save Bsize150" v-on="on">
                            <span class="I-save"></span>
                            <span class="T-size-18">Save</span>
                        </v-btn>                        
                    </template>
                    <v-card id="dialog" class="TF type1">
                        <div class="b-content">
                            <div class="B-dialog save">
                                <div class="t-title">Confirm Save</div>
                                <div class="t-des">Are you sure you want to save this item? </div>
                            </div>
                            <div class="box-btn">
                                <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialog = false">
                                    <span class="T-size-18">cancel</span>
                                </v-btn>
                                <v-btn class="theme-btn-even btn-save Bsize100" text @click="dialog = false">
                                    <span class="T-size-18">Save</span>
                                </v-btn>
                            </div>
                        </div>
                    </v-card>
                </v-dialog>
            </div>
        </div>

        <div class="box-S1">
            <div class="box-S4">
                <div class="N-Page T-size-18">Delete</div>
            </div>
            <div class="box-S4">
                <v-dialog v-model="dialog" persistent max-width="300">
                    <template v-slot:activator="{ on }">
                        <v-btn class="theme-btn-even btn-delete Bsize150" v-on="on">
                            <span class="I-bin"></span>
                            <span class="T-size-18">Delete</span>
                        </v-btn>                        
                    </template>
                    <v-card id="dialog" class="TF type1">
                        <div class="b-content">
                            <div class="B-dialog delete">
                                <div class="t-title">Confirm Deletion</div>
                                <div class="t-des">Are you sure you want to permanently delete this item?</div>
                            </div>
                            <div class="box-btn">
                                <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialog = false">
                                    <span class="T-size-18">cancel</span>
                                </v-btn>
                                <v-btn class="theme-btn-even btn-delete Bsize100" text @click="dialog = false">
                                    <span class="T-size-18">Delete</span>
                                </v-btn>
                            </div>
                        </div>
                    </v-card>
                </v-dialog>
            </div>
        </div>

        <div class="box-S1">
            <div class="box-S4">
                <div class="N-Page T-size-18">Complete</div>
            </div>
            <div class="box-S4">
                <v-dialog v-model="dialog" persistent max-width="300">
                    <template v-slot:activator="{ on }">
                        <v-btn class="theme-btn-even btn-ok Bsize150" v-on="on">
                            <span class="T-size-18">ok</span>
                        </v-btn>                        
                    </template>
                    <v-card id="dialog" class="TF type1">
                        <div class="b-content">
                            <div class="B-dialog confirm">
                                <div class="t-title">Save Complete</div>
                                <div class="t-des">Save Data Complete <br> You want to Create Transport? 
                                    <v-btn class="theme-btn-even btn-transport center Bsize190">
                                        <span class="I-transport"></span>
                                        <span class="T-size-18">Create Transport</span>
                                    </v-btn>
                                </div>
                            </div>
                            <div class="box-btn">
                                <v-btn class="theme-btn-even btn-ok Bsize100" text @click="dialog = false">
                                    <span class="T-size-18">OK</span>
                                </v-btn>
                            </div>
                        </div>
                    </v-card>
                </v-dialog>
            </div>
        </div>
        
    </div> -->
    <div class="wrap-Main flex-between-start">

        <div class="box-S1">
            <div class="box-S4">
                <div class="N-Page T-size-18">Add Leg</div>
            </div>
            <div class="box-S4">
                <v-dialog v-model="dialog" persistent max-width="400">
                    <template v-slot:activator="{ on }">
                        <v-btn class="theme-btn-even" v-on="on">
                            <span class="I-create"></span>
                            <span>Add Leg</span>
                        </v-btn>
                    </template>
                    <v-card id="dialog" class="TF type2">
                        <div class="b-content">
                            <div class="B-dialogform">
                                <div class="t-namepage ">Add Leg</div>
                                <div class="b-form">
                                   <v-select class="hideMessage" :items="items" label="Airport"></v-select>
                                    <div class="b-checkbox">
                                        <v-checkbox v-model="checkbox" :label="`S/D Refuel`"></v-checkbox>
                                        <v-checkbox v-model="checkbox" :label="`S/D Refuel and HLO familiarize`"></v-checkbox>
                                        <v-checkbox v-model="checkbox" :label="`S/D FOR JET A1 INSPECTION`"></v-checkbox>
                                        <v-checkbox v-model="checkbox" :label="`EDRILL-1`"></v-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div class="box-btn">
                                <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialog = false">
                                    <span class="T-size-18">cancel</span>
                                </v-btn>
                                <v-btn class="theme-btn-even btn-save Bsize100" text @click="dialog = false">
                                    <span class="T-size-18">Save</span>
                                </v-btn>
                            </div>
                        </div>
                    </v-card>
                </v-dialog>
            </div>
        </div>

      <!--   <div class="box-S1">
            <div class="box-S4">
                <div class="N-Page T-size-18">Edit Leg</div>
            </div>
            <div class="box-S4">
                <v-dialog v-model="dialog" persistent max-width="400">
                    <template v-slot:activator="{ on }">
                        <v-btn class="theme-btn-even" v-on="on">
                            <span class="I-create"></span>
                            <span>Edit Leg</span>
                        </v-btn>
                    </template>
                    <v-card id="dialog" class="TF type2">
                        <div class="b-content">
                            <div class="B-dialogform">
                                <div class="t-namepage ">Edit Leg</div>
                                <div class="b-form">
                                   <v-select class="hideMessage" :items="items" label="Airport"></v-select>
                                    <div class="b-checkbox">
                                        <v-checkbox v-model="checkbox" :label="`S/D Refuel`"></v-checkbox>
                                        <v-checkbox v-model="checkbox" :label="`S/D Refuel and HLO familiarize`"></v-checkbox>
                                        <v-checkbox v-model="checkbox" :label="`S/D FOR JET A1 INSPECTION`"></v-checkbox>
                                        <v-checkbox v-model="checkbox" :label="`EDRILL-1`"></v-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div class="box-btn">
                                <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialog = false">
                                    <span class="T-size-18">cancel</span>
                                </v-btn>
                                <v-btn class="theme-btn-even btn-save Bsize100" text @click="dialog = false">
                                    <span class="T-size-18">Save</span>
                                </v-btn>
                            </div>
                        </div>
                    </v-card>
                </v-dialog>
            </div>
        </div>-->

    </div> 
  <!--   <div class="wrap-Main flex-between-start">

        <div class="box-S1">
            <div class="box-S4">
                <div class="N-Page T-size-18">Add Passenger</div>
            </div>
            <div class="box-S4">
                <v-dialog v-model="dialog" persistent max-width="400">
                    <template v-slot:activator="{ on }">
                        <v-btn class="theme-btn-even" v-on="on">
                            <span class="I-create"></span>
                            <span>Add Passenger</span>
                        </v-btn>
                    </template>
                    <v-card id="dialog" class="TF type2">
                        <div class="b-content">
                            <div class="B-dialogform step">
                                <div class="t-namepage ">Add Passenger</div>
                                <div class="b-form">
                                    <v-stepper v-model="e1">
                                        <v-stepper-header>
                                            <v-stepper-step :complete="e1 > 1" step="1"></v-stepper-step>
                                                <v-divider></v-divider>
                                            <v-stepper-step :complete="e1 > 2" step="2"></v-stepper-step>
                                                <v-divider></v-divider>
                                            <v-stepper-step step="3"></v-stepper-step>
                                        </v-stepper-header>

                                        <v-stepper-items>
                                            <v-stepper-content step="1">
                                                <div class="b-contentstep">
                                                    <div class="box-btn-select">
                                                        <v-btn class="theme-btn-even btn-select">
                                                            <span class="I-create"></span>
                                                            <span>Select Passenger</span>
                                                        </v-btn>
                                                    </div>
                                                    <div class="B-passenger TF">
                                                        <div>
                                                        <div class="b-person">
                                                            <div class="b-display">
                                                            <div class="B-display person1"></div>
                                                            </div>
                                                            <div class="b-name-company">
                                                            <p class="t-name">sarawut Khattiyavayu</p>
                                                            <div class="t-company T-size-12">Company : CPOC</div>
                                                            </div>
                                                        </div>
                                                        <div class="b-detail">
                                                            <div class="t-title">Remark</div>
                                                            <div class="box-S4 flex-between-center t-detail">
                                                                <v-text-field label="Remark"></v-text-field>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="box-btn">
                                                    <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialog = false">
                                                        <span class="T-size-18">cancel</span>
                                                    </v-btn>
                                                    <v-btn class="theme-btn-even btn-ok Bsize100" @click="e1 = 2">
                                                        <span class="T-size-18">next</span>
                                                    </v-btn>
                                                </div>
                                            </v-stepper-content>

                                            <v-stepper-content step="2">
                                                <div class="b-contentstep">
                                                    <div class="B-passenger TF">
                                                        <div class="b-detail">
                                                            <div class="t-title">Destination</div>
                                                            <div class="box-S4 flex-between-center ">
                                                                <div class="box-S2 t-detail">
                                                                    <v-select class="hideMessage" :items="items" label="Departure From"></v-select>
                                                                </div>
                                                                <div class="box-S2 t-detail">
                                                                    <v-select class="hideMessage" :items="items" label="Destination"></v-select>
                                                                </div>                                                               
                                                            </div>
                                                            <div class="b-checkbox">
                                                                <v-checkbox v-model="checkbox" :label="`Inter Field`"></v-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                                            
                                                <div class="box-btn">
                                                    <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="e1 = 1">
                                                        <span class="T-size-18">cancel</span>
                                                    </v-btn>
                                                    <v-btn class="theme-btn-even btn-ok Bsize100" @click="e1 = 3">
                                                        <span class="T-size-18">next</span>
                                                    </v-btn>
                                                </div>
                                            </v-stepper-content>

                                            <v-stepper-content step="3">
                                                <div class="b-contentstep">
                                                    <div class="B-passenger TF">
                                                        <div class="b-detail">
                                                            <div class="t-title">Weight</div>
                                                            <div class="box-S4 flex-between-center ">
                                                                <div class="box-S2 t-detail">
                                                                    <v-select class="hideMessage" :items="items" label="Body Weight"></v-select>
                                                                </div>
                                                                <div class="box-S2 t-detail">
                                                                    <v-select class="hideMessage" :items="items" label="Baggage Weight"></v-select>
                                                                </div>                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                                            

                                                <div class="box-btn">
                                                    <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="e1 = 2">
                                                        <span class="T-size-18">cancel</span>
                                                    </v-btn>
                                                    <v-btn class="theme-btn-even btn-save Bsize100" text @click="dialog = false">
                                                        <span class="T-size-18">Add</span>
                                                    </v-btn>
                                                </div>
                                            </v-stepper-content>
                                        </v-stepper-items>
                                    </v-stepper>
                                </div>
                            </div>
                        </div>
                    </v-card>
                </v-dialog>
            </div>
        </div

        <div class="box-S1">
            <div class="box-S4">
                <div class="N-Page T-size-18">Edit Passenger</div>
            </div>
            <div class="box-S4">
                <v-dialog v-model="dialog" persistent max-width="400">
                    <template v-slot:activator="{ on }">
                        <v-btn class="theme-btn-even" v-on="on">
                            <span class="I-create"></span>
                            <span>Add Passenger</span>
                        </v-btn>
                    </template>
                    <v-card id="dialog" class="TF type2">
                        <div class="b-content">
                            <div class="B-dialogform step">
                                <div class="t-namepage ">Add Passenger</div>
                                <div class="b-form">
                                    <v-stepper v-model="e2">
                                        <v-stepper-header>
                                            <v-stepper-step :complete="e2 > 1" step="1"></v-stepper-step>
                                                <v-divider></v-divider>
                                            <v-stepper-step :complete="e2 > 2" step="2"></v-stepper-step>
                                                <v-divider></v-divider>
                                            <v-stepper-step step="3"></v-stepper-step>
                                        </v-stepper-header>

                                        <v-stepper-items>
                                            <v-stepper-content step="1">
                                                <div class="b-contentstep">
                                                    <div class="B-passenger TF">
                                                        <div>
                                                        <div class="b-person">
                                                            <div class="b-display">
                                                            <div class="B-display person1"></div>
                                                            </div>
                                                            <div class="b-name-company">
                                                            <p class="t-name">sarawut Khattiyavayu</p>
                                                            <div class="t-company T-size-12">Company : CPOC</div>
                                                            </div>
                                                        </div>
                                                        <div class="b-detail">
                                                            <div class="t-title">Remark</div>
                                                            <div class="box-S4 flex-between-center t-detail">
                                                                <v-text-field label="Remark"></v-text-field>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="box-btn">
                                                    <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialog = false">
                                                        <span class="T-size-18">cancel</span>
                                                    </v-btn>
                                                    <v-btn class="theme-btn-even btn-ok Bsize100" @click="e2 = 2">
                                                        <span class="T-size-18">next</span>
                                                    </v-btn>
                                                </div>
                                            </v-stepper-content>

                                            <v-stepper-content step="2">
                                                <div class="b-contentstep">
                                                    <div class="B-passenger TF">
                                                        <div class="b-detail">
                                                            <div class="t-title">Destination</div>
                                                            <div class="box-S4 flex-between-center ">
                                                                <div class="box-S2 t-detail">
                                                                    <v-select class="hideMessage" :items="items" label="Departure From"></v-select>
                                                                </div>
                                                                <div class="box-S2 t-detail">
                                                                    <v-select class="hideMessage" :items="items" label="Destination"></v-select>
                                                                </div>                                                               
                                                            </div>
                                                            <div class="b-checkbox">
                                                                <v-checkbox v-model="checkbox" :label="`Inter Field`"></v-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                                            
                                                <div class="box-btn">
                                                    <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="e2 = 1">
                                                        <span class="T-size-18">cancel</span>
                                                    </v-btn>
                                                    <v-btn class="theme-btn-even btn-ok Bsize100" @click="e2 = 3">
                                                        <span class="T-size-18">next</span>
                                                    </v-btn>
                                                </div>
                                            </v-stepper-content>

                                            <v-stepper-content step="3">
                                                <div class="b-contentstep">
                                                    <div class="B-passenger TF">
                                                        <div class="b-detail">
                                                            <div class="t-title">Weight</div>
                                                            <div class="box-S4 flex-between-center ">
                                                                <div class="box-S2 t-detail">
                                                                    <v-select class="hideMessage" :items="items" label="Body Weight"></v-select>
                                                                </div>
                                                                <div class="box-S2 t-detail">
                                                                    <v-select class="hideMessage" :items="items" label="Baggage Weight"></v-select>
                                                                </div>                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                                            

                                                <div class="box-btn">
                                                    <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="e2 = 2">
                                                        <span class="T-size-18">cancel</span>
                                                    </v-btn>
                                                    <v-btn class="theme-btn-even btn-save Bsize100" text @click="dialog = false">
                                                        <span class="T-size-18">save</span>
                                                    </v-btn>
                                                </div>
                                            </v-stepper-content>
                                        </v-stepper-items>
                                    </v-stepper>
                                </div>
                            </div>
                        </div>
                    </v-card>
                </v-dialog>
            </div>
        </div> 
        <div class="box-S1">
            <div class="box-S4">
                <div class="N-Page T-size-18">Add Passenger Transport</div>
            </div>
            <div class="box-S4">
                <v-dialog v-model="dialog" persistent max-width="400">
                    <template v-slot:activator="{ on }">
                        <v-btn class="theme-btn-even" v-on="on">
                            <span class="I-create"></span>
                            <span>Add Passenger Transport</span>
                        </v-btn>
                    </template>
                    <v-card id="dialog" class="TF type2">
                        <div class="b-content">
                            <div class="B-dialogform step">
                                <div class="t-namepage ">Add Passenger</div>
                                <div class="b-form">
                                    <v-stepper v-model="e3">
                                        <v-stepper-header>
                                            <v-stepper-step :complete="e3 > 1" step="1"></v-stepper-step>
                                                <v-divider></v-divider>
                                            <v-stepper-step step="2"></v-stepper-step>
                                        </v-stepper-header>

                                        <v-stepper-items>
                                            <v-stepper-content step="1">
                                                <div class="b-contentstep">
                                                    <div class="box-btn-select">
                                                        <v-btn class="theme-btn-even btn-select">
                                                            <span class="I-create"></span>
                                                            <span>Select Passenger</span>
                                                        </v-btn>
                                                    </div>
                                                    <div class="B-passenger TF">
                                                        <div>
                                                        <div class="b-person">
                                                            <div class="b-display">
                                                                <div class="B-display person1"></div>
                                                            </div>
                                                            <div class="b-name-company">
                                                                <p class="t-name">sarawut Khattiyavayu</p>
                                                                <div class="t-company T-size-12">Company : CPOC</div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="box-btn">
                                                    <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialog = false">
                                                        <span class="T-size-18">cancel</span>
                                                    </v-btn>
                                                    <v-btn class="theme-btn-even btn-ok Bsize100" @click="e3 = 2">
                                                        <span class="T-size-18">next</span>
                                                    </v-btn>
                                                </div>
                                            </v-stepper-content>

                                            <v-stepper-content step="2">
                                                <div class="b-contentstep">
                                                    <div class="B-passenger TF">
                                                        <div class="b-detail">
                                                            <div class="t-title">Loacation</div>
                                                            <div class="box-S4 flex-between-center ">
                                                                <div class="box-S2 t-detail">
                                                                    <v-select class="hideMessage" :items="items" label="Pickup"></v-select>
                                                                    <v-select class="hideMessage" :items="items" label="Date & Time"></v-select>
                                                                </div>
                                                                <div class="box-S2 t-detail">
                                                                    <v-select class="hideMessage" :items="items" label="Drop"></v-select>
                                                                    <v-select class="hideMessage" :items="items" label="Date & Time"></v-select>
                                                                </div>                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                                            

                                                <div class="box-btn">
                                                    <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="e3 = 1">
                                                        <span class="T-size-18">Back</span>
                                                    </v-btn>
                                                    <v-btn class="theme-btn-even btn-save Bsize100" text @click="dialog = false">
                                                        <span class="T-size-18">Add</span>
                                                    </v-btn>
                                                </div>
                                            </v-stepper-content>
                                        </v-stepper-items>
                                    </v-stepper>
                                </div>
                            </div>
                        </div>
                    </v-card>
                </v-dialog>
            </div>
        </div>


        <div class="box-S1">
            <div class="box-S4">
                <div class="N-Page T-size-18">Edit Passenger Transport</div>
            </div>
            <div class="box-S4">
                <v-dialog v-model="dialog" persistent max-width="400">
                    <template v-slot:activator="{ on }">
                        <v-btn class="theme-btn-even" v-on="on">
                            <span class="I-create"></span>
                            <span>Edit Passenger Transport</span>
                        </v-btn>
                    </template>
                    <v-card id="dialog" class="TF type2">
                        <div class="b-content">
                            <div class="B-dialogform step edit">
                                <div class="t-namepage ">Edit Loacation</div>
                                <div class="b-form">
                                    <div class="b-contentstep">
                                        <div class="B-passenger TF">
                                            <div class="b-detail">
                                                <div class="box-S4 flex-between-center ">
                                                    <div class="box-S2 t-detail">
                                                        <v-select class="hideMessage" :items="items" label="Pickup"></v-select>
                                                        <v-select class="hideMessage" :items="items" label="Date & Time"></v-select>
                                                    </div>
                                                    <div class="box-S2 t-detail">
                                                        <v-select class="hideMessage" :items="items" label="Drop"></v-select>
                                                        <v-select class="hideMessage" :items="items" label="Date & Time"></v-select>
                                                    </div>                                                               
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                            

                                    <div class="box-btn">
                                        <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="e4 = 1">
                                            <span class="T-size-18">cancel</span>
                                        </v-btn>
                                        <v-btn class="theme-btn-even btn-save Bsize100" text @click="dialog = false">
                                            <span class="T-size-18">save</span>
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-card>
                </v-dialog>
            </div>
        </div> 

    </div>>-->
<!-- 
    <div class="wrap-Main flex-between-start">

        <div class="box-S1">
            <div class="box-S4">
                <div class="N-Page T-size-18">Add Leg Create Flight</div>
            </div>
            <div class="box-S4">
                <v-dialog v-model="dialog" persistent max-width="400">
                    <template v-slot:activator="{ on }">
                        <v-btn class="theme-btn-even" v-on="on">
                            <span class="I-create"></span>
                            <span>Add Leg</span>
                        </v-btn>
                    </template>
                    <v-card id="dialog" class="TF type3">
                        <div class="b-content">
                            <div class="B-dialogform">
                                <div class="t-namepage ">Add Leg</div>
                                <div class="b-form">
                                   <v-select class="hideMessage" :items="items" label="Airport"></v-select>
                                    <div class="b-checkbox">
                                        <v-checkbox v-model="checkbox" :label="`S/D Refuel`"></v-checkbox>
                                        <v-checkbox v-model="checkbox" :label="`S/D Refuel and HLO familiarize`"></v-checkbox>
                                        <v-checkbox v-model="checkbox" :label="`S/D FOR JET A1 INSPECTION`"></v-checkbox>
                                        <v-checkbox v-model="checkbox" :label="`EDRILL-1`"></v-checkbox>
                                    </div>
                                </div>
                                <div class="t-namepage2">Time</div>
                                <div class="b-form">
                                    <div class="b-detail">
                                        <div class="box-S4 flex-between-center noPadding">
                                            <div class="box-S2 noPadding t-detail">
                                                <v-text-field class="hideMessage" label="Est. Arrival Time"></v-text-field>
                                            </div>
                                            <div class="box-S2 noPadding t-detail">
                                                <v-text-field class="hideMessage" label="Est. Departure Time"></v-text-field>
                                            </div>                                                               
                                        </div>
                                    </div>
                                </div>
                                <div class="t-namepage2">Weather Data</div>
                                <div class="b-form">
                                    <div class="b-detail">
                                        <div class="box-S4 flex-between-center noPadding">
                                            <div class="box-S2 noPadding t-detail">
                                                <v-text-field class="hideMessage" label="Visibility (KM)"></v-text-field>
                                                <v-text-field class="hideMessage" label="WindSpeed (Knot)"></v-text-field>
                                            </div>
                                            <div class="box-S2 noPadding t-detail">
                                                <v-text-field class="hideMessage" label="OAT (°C))"></v-text-field>
                                                <v-text-field class="hideMessage" label="QNH"></v-text-field>
                                            </div>                                                               
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="box-btn">
                                <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialog = false">
                                    <span class="T-size-18">cancel</span>
                                </v-btn>
                                <v-btn class="theme-btn-even btn-save Bsize100" text @click="dialog = false">
                                    <span class="T-size-18">save</span>
                                </v-btn>
                            </div>
                        </div>
                    </v-card>
                </v-dialog>
            </div>
        </div>

        <div class="box-S1">
            <div class="box-S4">
                <div class="N-Page T-size-18">Edit Leg Create Flight</div>
            </div>
            <div class="box-S4">
                <v-dialog v-model="dialog" persistent max-width="400">
                    <template v-slot:activator="{ on }">
                        <v-btn class="theme-btn-even" v-on="on">
                            <span class="I-create"></span>
                            <span>Edit Leg</span>
                        </v-btn>
                    </template>
                    <v-card id="dialog" class="TF type3">
                        <div class="b-content">
                            <div class="B-dialogform">
                                <div class="t-namepage ">Edit Leg</div>
                                <div class="b-form">
                                   <v-select class="hideMessage" :items="items" label="Airport"></v-select>
                                    <div class="b-checkbox">
                                        <v-checkbox v-model="checkbox" :label="`S/D Refuel`"></v-checkbox>
                                        <v-checkbox v-model="checkbox" :label="`S/D Refuel and HLO familiarize`"></v-checkbox>
                                        <v-checkbox v-model="checkbox" :label="`S/D FOR JET A1 INSPECTION`"></v-checkbox>
                                        <v-checkbox v-model="checkbox" :label="`EDRILL-1`"></v-checkbox>
                                    </div>
                                </div>
                                <div class="t-namepage2">Time</div>
                                <div class="b-form">
                                    <div class="b-detail">
                                        <div class="box-S4 flex-between-center noPadding">
                                            <div class="box-S2 noPadding t-detail">
                                                <v-text-field class="hideMessage" label="Est. Arrival Time"></v-text-field>
                                            </div>
                                            <div class="box-S2 noPadding t-detail">
                                                <v-text-field class="hideMessage" label="Est. Departure Time"></v-text-field>
                                            </div>                                                               
                                        </div>
                                    </div>
                                </div>
                                <div class="t-namepage2">Weather Data</div>
                                <div class="b-form">
                                    <div class="b-detail">
                                        <div class="box-S4 flex-between-center noPadding">
                                            <div class="box-S2 noPadding t-detail">
                                                <v-text-field class="hideMessage" label="Visibility (KM)"></v-text-field>
                                                <v-text-field class="hideMessage" label="WindSpeed (Knot)"></v-text-field>
                                            </div>
                                            <div class="box-S2 noPadding t-detail">
                                                <v-text-field class="hideMessage" label="OAT (°C))"></v-text-field>
                                                <v-text-field class="hideMessage" label="QNH"></v-text-field>
                                            </div>                                                               
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="box-btn">
                                <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialog = false">
                                    <span class="T-size-18">cancel</span>
                                </v-btn>
                                <v-btn class="theme-btn-even btn-save Bsize100" text @click="dialog = false">
                                    <span class="T-size-18">save</span>
                                </v-btn>
                            </div>
                        </div>
                    </v-card>
                </v-dialog>
            </div>
        </div>

    </div>

    <div class="wrap-Main flex-between-start">

        <div class="box-S1">
            <div class="box-S4">
                <div class="N-Page T-size-18">Add Cargo</div>
            </div>
            <div class="box-S4">
                <v-dialog v-model="dialog" persistent max-width="400">
                    <template v-slot:activator="{ on }">
                        <v-btn class="theme-btn-even" v-on="on">
                            <span class="I-create"></span>
                            <span>Add Cargo</span>
                        </v-btn>
                    </template>
                    <v-card id="dialog" class="TF type3">
                        <div class="b-content">
                            <div class="B-dialogform">
                                <div class="t-namepage ">Add Cargo</div>
                                <div class="b-form">
                                   <v-text-field class="hideMessage" label="Weight"></v-text-field>
                                   <v-select class="hideMessage" :items="items" label="Destination"></v-select>
                                   <v-text-field class="hideMessage" label="Consigned to"></v-text-field>
                                </div>
                                <div class="t-namepage2">Cargo Details</div>
                                <div class="b-form">
                                    <v-text-field class="hideMessage" label="Details"></v-text-field>
                                </div>
                            </div>
                            <div class="box-btn">
                                <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialog = false">
                                    <span class="T-size-18">cancel</span>
                                </v-btn>
                                <v-btn class="theme-btn-even btn-save Bsize100" text @click="dialog = false">
                                    <span class="T-size-18">save</span>
                                </v-btn>
                            </div>
                        </div>
                    </v-card>
                </v-dialog>
            </div>
        </div>

        <div class="box-S1">
            <div class="box-S4">
                <div class="N-Page T-size-18">Edit Cargo</div>
            </div>
            <div class="box-S4">
                <v-dialog v-model="dialog" persistent max-width="400">
                    <template v-slot:activator="{ on }">
                        <v-btn class="theme-btn-even" v-on="on">
                            <span class="I-create"></span>
                            <span>Edit Cargo</span>
                        </v-btn>
                    </template>
                    <v-card id="dialog" class="TF type3">
                        <div class="b-content">
                            <div class="B-dialogform">
                                <div class="t-namepage ">Edit Cargo</div>
                                <div class="b-form">
                                   <v-text-field class="hideMessage" label="Weight"></v-text-field>
                                   <v-select class="hideMessage" :items="items" label="Destination"></v-select>
                                   <v-text-field class="hideMessage" label="Consigned to"></v-text-field>
                                </div>
                                <div class="t-namepage2">Cargo Details</div>
                                <div class="b-form">
                                    <v-text-field class="hideMessage" label="Details"></v-text-field>
                                </div>
                            </div>
                            <div class="box-btn">
                                <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialog = false">
                                    <span class="T-size-18">cancel</span>
                                </v-btn>
                                <v-btn class="theme-btn-even btn-save Bsize100" text @click="dialog = false">
                                    <span class="T-size-18">save</span>
                                </v-btn>
                            </div>
                        </div>
                    </v-card>
                </v-dialog>
            </div>
        </div>


    </div> -->

  </div>
</template>

<script>
export default {
    data () {
        return {
            dialog: false,
            e1: 1,
            e2: 1,
            e3: 1,
            e4: 1,
        }
    },
};
</script>

<style>
</style>